import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/chatp/Projects/farming-season/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "ระบบเกม"
    }}>{`ระบบเกม`}</h1>
    <hr></hr>
    <h3 {...{
      "id": "การแปรรูป"
    }}>{`การแปรรูป`}</h3>
    <p>{`การแปรรูป สามารถทำได้โดยใช้ "ผลผลิตพิเศษ" กับเครื่องแปรรูปที่ฟาร์มของท่าน`}</p>
    <p><img alt="mat" src={require("./public/images/pro.png")} /></p>
    <p>{`รายชื่อของไอเทมแปรรูป จะแสดงขึ้นมาให้เห็นหากมีผลผลิตพิเศษที่ใช้ในการแปรรูปไอเทมชิ้นนั้น โดยในการแปรรูป จะเสียไอเทมผลผลิตพิเศษตามที่กำหนดไว้ในการสร้างไอเทมแปรรูปแต่ละชนิด และมีโอกาสในการแปรรูปสำเร็จกับไม่สำเร็จ `}</p>
    <p><img alt="mat" src={require("./public/images/pro-1.png")} /></p>
    <p>{`หากแปรรูปสำเร็จ จะได้รับไอเทมแปรรูป แต่ถ้าหากแปรรูปไม่สำเร็จ จะได้รับไอเทมผลผลิตพิเศษที่ใช้ในการแปรรูปคืนกลับมาบางส่วน`}</p>
    <p><img alt="mat" src={require("./public/images/pro-2.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      